<template>
	<!-- This is an example component -->
	<div>
		<div
			class="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center"
		>
			<div class="w-auto h-auto bg-white border rounded shadow-xl">
				<div class="flex justify-center mt-4">
					<p class="font-bold text-xl">Cambio de Proveedor</p>
				</div>
				<div
					v-if="proveedoresData.length > 0"
					class="flex justify-center w-84 mx-4"
					:class="proveedoresData.length > 6 ? 'h-72  overflow-y-auto' : ''"
				>
					<table id="Proveedores" class="table-auto mt-4">
						<thead>
							<tr>
								<th></th>
								<th class="border-2 px-4 py-2">Proveedor</th>
								<th class="border-2 px-4 py-2">Tasa</th>
								<th class="border-2 px-4 py-2">Precio Bs</th>
								<th class="border-2 px-4 py-2">Precio USD</th>
							</tr>
						</thead>
						<tbody v-for="(local, k) in proveedoresData" :key="k">
							<tr>
								<td>
									<input
										class="mr-2"
										type="radio"
										name="indexProveedor"
										id=""
										:value="parseInt(local.precioID)"
										v-model="precioId"
									/>
								</td>
								<td class="border-2 px-4 py-4 text-center">
									{{ firstLetterUpperCase(local.nombre) }}
								</td>
								<td class="border-2 px-4 py-4 text-center">
									{{ formato(local.tasa) }}
								</td>
								<td class="border-2 px-4 py-4 text-center">
									{{ formato(local.precioBs) }}
								</td>
								<td class="border-2 px-4 py-4 text-center">
									{{ formato(local.precioUSD) }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div
					v-else-if="this.$store.state.isLoading == false"
					class="flex justify-center w-68 mx-4 mt-4"
				>
					<p class="text-lg">
						No hay más proveedores asociados al producto al momento de la
						compra.
					</p>
				</div>
				<div class="flex justify-center my-6">
					<Botonp
						v-if="proveedoresData.length > 0"
						class="mr-10"
						botonpText="Seleccionar"
						@click="
							$emit('guardarCambioProveedor', {
								showWindow: false,
								precioID: precioId
							})
						"
					></Botonp>
					<Botonp
						botonpText="Cerrar"
						@click="$emit('cerrarVentana', false)"
					></Botonp>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Botonp from "@/components/botonPrimario";
import { formato, firstLetterUpperCase } from "@/functions.js";

export default {
	components: {
		Botonp
	},
	data() {
		return {
			proveedoresData: [],
			proveedoresName: [],
			idArray: [],
			precioId: 0,
			proveedoresArray: []
		};
	},
	props: {
		cambioProveedor: Array,
		proveedorIdActual: Number,
		arrayCambio: Array,
		proveedorCambiado: Boolean,
		idPedidoTabla: Number
	},
	created() {
		this.proveedoresArray = this.cambioProveedor.filter(
			e => parseInt(e.id) != parseInt(this.proveedorIdActual)
		);
		this.idArray = this.proveedoresArray.map(e => parseInt(e.id));
		if (this.proveedorCambiado) {
			let precioIdObject = this.arrayCambio.filter(e => {
				if (parseInt(e.id) == parseInt(this.idPedidoTabla)) {
					return parseInt(e.precios_id);
				}
			});
			this.precioId = parseInt(precioIdObject[0].precios_id);
		}
	},
	apollo: {
		proveedoresName: {
			query: require("@/graphql/queries/localIdNombre.gql"),
			variables() {
				// Use vue reactive properties here
				return {
					id: this.idArray
				};
			},
			update: data => data.localIdNombre,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},
	watch: {
		proveedoresName: function fillProveedoresData() {
			if (this.idArray.length > 0) {
				for (let i = 0; i < this.proveedoresArray.length; i++) {
					this.proveedoresData.push({
						nombre: this.proveedoresName[i].nombre,
						tasa: this.proveedoresArray[i].tasa_dia,
						precioBs: this.proveedoresArray[i].precio_dia_bs,
						precioUSD:
							parseFloat(this.proveedoresArray[i].precio_dia_bs) /
							parseFloat(this.proveedoresArray[i].tasa_dia),
						precioID: this.proveedoresArray[i].precios_id
					});
				}
			}
		}
	},
	methods: {
		formato,
		firstLetterUpperCase
	}
};
</script>

<style></style>
