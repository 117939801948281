<template>
	<div class="mt-20 md:mt-0">
		<fragment>
			<div
				class="flex w-full items-center justify-between mb-4 bg-verdedark text-white font-bold fixed md:static"
			>
				<span class="ml-2">{{
					this.$route.params.nombre.replaceAll("_", " ")
				}}</span>
				<div>
					<span>Filtrar por: </span>
					<select
						id="select"
						class="py-1 h-8 rounded-lg bg-verdedark border border-white text-white px-4 focus:outline-none"
						v-model="filtroBusqueda"
						@change="cargarDataProducto()"
					>
						<option value="todos">Todos</option>
						<option value="pagado">Pagado</option>
						<option value="noPagado">No Pagado</option>
						<option value="producto">Producto</option>
					</select>
				</div>
				<div class="flex justify-center">
					<router-link
						:to="{
							name: 'InventarioProveedor',
							params: {
								id: this.$route.params.id,
								user: this.$route.params.user,
								nombre: this.$route.params.nombre
							}
						}"
						><button
							class="font-bold py-2 px-4 inline-flex items-center focus:outline-none"
						>
							<Actualizar class="text-whit fill-current w-6 h-6 mr-1" /><span
								class="underline"
								>Actualizar inventario</span
							>
						</button></router-link
					>
				</div>
				<div class="flex justify-center">
					<router-link
						:to="{
							name: 'AgregarProveedor',
							params: {
								id: this.$route.params.id,
								nombre: this.$route.params.nombre,
								user: this.$route.params.user
							}
						}"
						><button
							@click="$emit('click')"
							class="font-bold py-2 inline-flex items-center focus:outline-none"
						>
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								class="h-6 w-6 mr-2 fill-current text-white"
							>
								<path
									d="M4.17473 2.06016C5.68679 0.550767 8.13538 0.550767 9.64744 2.06016L11.0174 3.4376L25.6002 18.0127L25.6312 18.0439C25.6387 18.0514 25.6387 18.0594 25.6467 18.0594C25.6622 18.0826 25.6854 18.1056 25.7007 18.1289C25.7007 18.1366 25.7087 18.1366 25.7087 18.1444C25.7242 18.1676 25.7317 18.1831 25.7474 18.2064C25.7549 18.2141 25.7549 18.2216 25.7627 18.2296C25.7704 18.2529 25.7782 18.2684 25.7861 18.2916C25.7861 18.2991 25.7936 18.2991 25.7936 18.3071L29.0291 28.0366C29.124 28.3136 29.0519 28.6203 28.8434 28.8259C28.6969 28.9704 28.4994 29.0513 28.2938 29.0506C28.2098 29.0491 28.1265 29.036 28.0461 29.0118L18.3242 25.7686C18.3166 25.7686 18.3166 25.7686 18.3087 25.7611C18.2842 25.7538 18.2607 25.7434 18.2392 25.7299C18.2331 25.7291 18.2278 25.7265 18.2234 25.7224C18.2004 25.7069 18.1694 25.6911 18.1462 25.6756C18.1232 25.6604 18.0997 25.6371 18.0765 25.6216C18.0687 25.6136 18.0612 25.6136 18.0612 25.6061C18.0532 25.5984 18.0377 25.5909 18.03 25.5751L2.07722 9.62239C0.567829 8.11033 0.567829 5.66174 2.07722 4.14992L4.17473 2.06016ZM18.5718 23.9495L23.9591 18.5625L10.4755 5.07895L5.08826 10.4659L18.5718 23.9495ZM24.7179 19.9944L20.0115 24.7006L27.0786 27.0535L24.7179 19.9944ZM3.16847 8.53865L3.98903 9.36695L9.3765 3.97947L8.54796 3.15141C7.64169 2.24587 6.17273 2.24587 5.26622 3.15141L3.16096 5.25667C2.26148 6.16705 2.26487 7.63262 3.16847 8.53865Z"
									fill="white"
								/>
							</svg>
							<span class="text-white underline mr-1">Editar proveedor</span>
						</button></router-link
					>
				</div>
			</div>
			<div class="flex justify-between">
				<div
					class="ml-5 border-gray-300 border h-32 rounded-lg grid items-center"
					:class="filtroBusqueda == 'producto' ? 'w-80' : 'w-2/5'"
				>
					<div class="flex ml-12">
						<div>
							<span class="font-bold mr-2">Fecha Inicio:</span>
							<input
								class="w-36 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
								type="date"
								v-model="fechaInicio"
							/>
						</div>
						<div class="w-36">
							<span class="font-bold mx-2">Fecha Fin:</span>
							<input
								class="w-36 border border-secundB rounded-md bg-secund hover:bg-gray-300 text-black font-bold items-center px-2 py-1 focus:outline-none"
								type="date"
								v-model="fechaFin"
							/>
						</div>
					</div>
					<div class="flex justify-around">
						<Botonp
							class="underline"
							botonpText="Reset"
							@click="resetData()"
						></Botonp>
						<Botonp
							class="underline"
							botonpText="Buscar"
							@click="nuevaBusqueda()"
						></Botonp>
					</div>
				</div>

				<div v-if="filtroBusqueda != 'producto'">
					<div class="flex justify-center mt-10 md:mt-0">
						<Campo
							campoTitulo="Total por pagar USD: "
							:campoCoti="
								formato(this.cuentasPagarProveedor.totalOrdenesPagarUsd)
							"
							class="mx-10"
						/>
						<Campo
							campoTitulo="Total por pagar Bs: "
							:campoCoti="
								formato(this.cuentasPagarProveedor.totalOrdenesPagarBs)
							"
							class="mx-10"
						/>
					</div>
					<div v-if="filtroFecha" class="flex justify-center mt-10 md:mt-2">
						<Campo
							campoTitulo="Total pagado USD: "
							:campoCoti="
								formato(this.cuentasPagarProveedor.totalOrdenesPagadoUsd)
							"
							class="mx-10"
						/>
						<Campo
							campoTitulo="Total pagado Bs: "
							:campoCoti="
								formato(this.cuentasPagarProveedor.totalOrdenesPagadoBs)
							"
							class="mx-10"
						/>
					</div>
					<div class="w-48">
						<Campo
							campoTitulo="Descuento general: "
							:campoCoti="formato(descuentoProveedor)"
							class="ml-10 my-3"
						/>
					</div>
				</div>
				<div class="mr-64 w-64" v-else>
					<span class="font-bold mx-2">Elija un producto:</span>
					<vSelect
						:options="localProductos"
						placeholder="Elija un producto"
						v-model="productoOrdenCompra"
						@input="nuevaBusqueda()"
					></vSelect>
				</div>
			</div>

			<div class="flex items-end justify-center mt-4 mb-4"></div>
			<div v-if="filtroBusqueda != 'producto'">
				<div class="flex justify-center">
					<span v-if="showMessage">{{ message }}</span>
				</div>
				<div class="flex justify-center">
					<table id="Proveedores" class="table-auto mt-4">
						<thead>
							<tr>
								<th class="border-2 px-4 py-2"># Orden de compra</th>
								<th class="border-2 px-4 py-2">Fecha de compra</th>
								<th class="border-2 px-4 py-2">Cuentas por pagar Bs</th>
								<th class="border-2 px-4 py-2">Cuentas por pagar $</th>
								<th class="border-2 px-4 py-2">Detalle</th>
								<th class="border-2 px-4 py-2">Estado</th>
							</tr>
						</thead>
						<tbody
							v-for="(compra, k) in cuentasPagarProveedor.listaOrdenCompra"
							:key="k"
						>
							<tr>
								<td class="border-2 px-4 py-4 text-center">
									<router-link
										class="hover:text-blue-500 hover:underline text-center"
										:to="{
											name: 'PedidoID',
											params: {
												id: compra.id.padStart(4, '0')
											}
										}"
										>{{ compra.id.padStart(4, "0") }}</router-link
									>
								</td>
								<td class="border-2 px-4 py-4 text-center">
									{{ formatoFecha(compra.fecha_entrega) }}
								</td>
								<td class="border-2 px-4 py-4 text-center">
									{{ formato(compra.total_bs) }}
								</td>
								<td class="border-2 px-4 py-1 text-center">
									{{ formato(compra.total_usd) }}
								</td>
								<td class="border-2 px-4 py-4 text-center">
									<input
										type="button"
										class="bg-blue-200 hover:bg-blue-300 rounded-md border w-24 text-gray-900"
										value="Ver Detalle"
										@click="mostrarLista(k)"
									/>
								</td>
								<td class="border-2 border-l-2 px-4 py-4">
									<vSelect
										class="w-40"
										:options="['No Pagado', 'Pagado']"
										:key="selectRefresh[k]"
										:placeholder="verificarEstado(compra.estado)"
										@input="estado => cambiarEstado(compra, estado)"
									></vSelect>
								</td>
							</tr>
							<tr v-if="mostrarDetalle[k]">
								<td colspan="6" class="w-full py-3">
									<ListaProductos
										:listaProductos="compra.lista_productos"
										:arrayStyle="cambioProveedorStyle[k]"
										:baseIndex="k"
										@cambiarProveedor="cambiarProveedor"
									></ListaProductos>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div v-else>
				<div class="flex justify-center">
					<span v-if="showMessage">{{ message }}</span>
				</div>
				<div class="flex justify-center">
					<ListaProductosAcumulado
						:listaProductosOrdenCompra="listaProductosOrdenCompra"
						:totalUsd="totalUsd"
						:totalBs="totalBs"
					></ListaProductosAcumulado>
				</div>
			</div>
		</fragment>
		<div v-if="mostrarCambioProveedor">
			<CambioProveedorView
				@cerrarVentana="cerrarVentana"
				@guardarCambioProveedor="guardarNuevoPrecioId"
				:cambioProveedor="cambioProveedoresObject.proveedoresJson"
				:proveedorIdActual="parseInt(this.$route.params.id)"
				:arrayCambio="finalArrayCambioProveedores"
				:proveedorCambiado="
					cambioProveedorStyle[cambioProveedoresObject.styleIndex.parentIndex][
						cambioProveedoresObject.styleIndex.childIndex
					]
				"
				:idPedidoTabla="parseInt(cambioProveedoresObject.pedidoId)"
			/>
		</div>
		<div v-if="filtroBusqueda != 'producto'" class="flex justify-between">
			<div class="pl-32 py-4">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="data[0].dataInicio > 0"
					@click="cargarDataAnterior()"
				>
					Anterior
				</button>
			</div>
			<div class="pr-40 py-4">
				<button
					class="bg-gray-500 text-white rounded-md border w-24 text-lg font-bold h-10 hover:bg-azul"
					v-if="cuentasPagarProveedor.continuaData"
					@click="cargarDataSiguiente()"
				>
					Siguiente
				</button>
			</div>
		</div>
		<div v-if="filtroBusqueda != 'producto'" class="flex justify-center mb-5">
			<span v-if="showMessage">{{ message }}</span>
		</div>
		<div v-if="filtroBusqueda != 'producto'" class="flex justify-center mb-10">
			<div>
				<Botonp
					class="underline"
					botonpText="Guardar Cambios"
					@click="guardarCambios"
				></Botonp>
			</div>
		</div>
	</div>
</template>

<script>
import Campo from "@/components/camposCotizacion";
import Actualizar from "@/components/refresh";
import gql from "graphql-tag";
import "@/assets/styles/vue-select.css";
import { Fragment } from "vue-fragment";
import vSelect from "vue-select";
import Botonp from "@/components/botonPrimario";
import ListaProductos from "@/components/TarjetaListaProductosOrdenCompraProveedor";
import ListaProductosAcumulado from "@/components/TarjetaProductoOrdenCompraProveedor";
import CambioProveedorView from "@/components/CambioProveedorProducto.vue";
import {
	formato,
	firstLetterUpperCase,
	formatoFecha,
	productoData
} from "@/functions.js";

export default {
	name: "Proveedores",
	components: {
		Campo,
		Actualizar,
		Fragment,
		vSelect,
		Botonp,
		ListaProductos,
		ListaProductosAcumulado,
		CambioProveedorView
	},
	data: function() {
		return {
			data: [
				{
					id: 0,
					fechaInicio: null,
					fechaFin: null,
					dataInicio: 0,
					dataFin: 0,
					filtro: null,
					producto: null
				}
			],
			cambioProveedorStyle: [],
			productoOrdenCompra: null,
			fechaInicio: null,
			fechaFin: null,
			cuentasPagarProveedor: [],
			mostrarDetalle: [],
			filtroBusqueda: "noPagado",
			localProductos: [],
			selectRefresh: [],
			listaProductosOrdenCompra: [],
			totalUsd: 0,
			totalBs: 0,
			showMessage: true,
			message: "",
			descuentoProveedor: "",
			mostrarCambioProveedor: false,
			cambioProveedoresObject: {
				pedidoId: "",
				proveedoresJson: []
			},
			finalArrayCambioProveedores: [],
			filtroFecha: false
		};
	},
	apollo: {
		cuentasPagarProveedor: {
			query: require("@/graphql/queries/cuentasPagarProveedor.gql"),
			variables() {
				// Use vue reactive properties here
				return {
					data: this.data
				};
			},
			fetchPolicy: "cache-and-network",
			update: data => data.cuentasPagarProveedor[0],
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},
	watch: {
		cuentasPagarProveedor: function totalProveedor() {
			this.cambioProveedorStyle = [];
			this.cuentasPagarProveedor.listaOrdenCompra.forEach(() => {
				this.cambioProveedorStyle.push([]);
			});

			this.showMessage = true;
			this.message = "Completado";
			//Variable de control para mostrar o no detalles
			for (let i = 0; i < 10; i++) {
				this.mostrarDetalle.push(false);
				this.selectRefresh[i] = !this.selectRefresh[i];
			}
			this.$store.state.isLoading = false;
		}
	},
	async mounted() {
		this.data = [
			{
				id: parseInt(this.$route.params.id),
				fechaInicio: null,
				fechaFin: null,
				dataInicio: 0,
				dataFin: 10,
				filtro: "noPagado",
				producto: null
			}
		];
		for (let i = 0; i < 10; i++) {
			this.selectRefresh.push(true);
		}
		this.$store.state.isLoading = true;
		await this.queryPorcentajeProveedor();
		this.$store.state.isLoading = false;
	},
	methods: {
		formato,
		firstLetterUpperCase,
		formatoFecha,
		productoData,
		guardarNuevoPrecioId(event) {
			let arrayCambioProveedores = [];
			this.mostrarCambioProveedor = event.showWindow;
			arrayCambioProveedores = this.finalArrayCambioProveedores.filter(
				e => parseInt(e.id) == parseInt(this.cambioProveedoresObject.pedidoId)
			);
			if (arrayCambioProveedores.length > 0) {
				this.finalArrayCambioProveedores.map((e, index) => {
					if (
						parseInt(e.id) == parseInt(this.cambioProveedoresObject.pedidoId)
					) {
						this.finalArrayCambioProveedores[index].precios_id = event.precioID;
					}
				});
			} else {
				this.finalArrayCambioProveedores.push({
					id: parseInt(this.cambioProveedoresObject.pedidoId),
					precios_id: event.precioID
				});
			}
			this.$set(
				this.cambioProveedorStyle[
					this.cambioProveedoresObject.styleIndex.parentIndex
				],
				this.cambioProveedoresObject.styleIndex.childIndex,
				true
			);
		},
		cerrarVentana(event) {
			this.mostrarCambioProveedor = event;
		},
		cambiarProveedor(event) {
			this.mostrarCambioProveedor = event.showWindow;
			this.cambioProveedoresObject = {
				pedidoId: event.pedidoId,
				proveedoresJson: JSON.parse(event.proveedores),
				styleIndex: event.styleIndex
			};
		},
		async queryPorcentajeProveedor() {
			await this.$apollo
				.query({
					query: gql`
						query localId($id: ID!) {
							localId(id: $id) {
								descuento_general
							}
						}
					`,
					variables: {
						id: parseInt(this.$route.params.id)
					}
				})
				.then(data => {
					this.descuentoProveedor = data.data.localId[0].descuento_general;
				});
		},
		cargarDataProducto() {
			if (this.filtroBusqueda === "producto") {
				this.productosAsociadosProveedor();
			}
		},
		async listaProductoOrdenCompraAcumulado() {
			this.$store.state.isLoading = true;
			this.showMessage = true;
			this.message = "Cargando, espere por favor";
			await this.$apollo
				.query({
					query: require("@/graphql/queries/listaProductosOrdenCompraAcumulado.gql"),
					variables: {
						data: this.data
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.listaProductosOrdenCompra = data.data.listaProductoOrdenCompra;
					this.showMessage = true;
					this.message = "Completado";
					//Acumulado por rubro busqueda.
					this.totalUsd = 0;
					this.totalBs = 0;
					let tasaProveerdorObjeto = {};
					let tasaDiaProveedor = 0;
					for (let i = 0; i < this.listaProductosOrdenCompra.length; i++) {
						try {
							tasaProveerdorObjeto = JSON.parse(
								this.listaProductosOrdenCompra[i].tasa_proveedores
							);
							tasaProveerdorObjeto.forEach(element => {
								if (parseInt(element.id) == parseInt(this.$route.params.id)) {
									tasaDiaProveedor = parseFloat(element.tasa_dia);
								}
							});
							this.totalUsd += parseFloat(
								this.listaProductosOrdenCompra[i].total_usd
							);
							this.totalBs +=
								parseFloat(this.listaProductosOrdenCompra[i].total_usd) *
								tasaDiaProveedor;
						} catch (error) {
							console.log(error);
							tasaDiaProveedor = parseFloat(
								this.listaProductosOrdenCompra[i].tasa_dia
							);
						}
					}
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.showMessage = true;
					this.message = "Error en carga, por favor vuelva a intentarlo";
					this.$store.state.isLoading = false;
				});
		},
		async productosAsociadosProveedor() {
			this.showMessage = true;
			this.message = "Cargando, por favor espere";
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: gql`
						query localId($id: ID!) {
							localId(id: $id) {
								precio {
									producto {
										id
										producto
										rubro
									}
								}
							}
						}
					`,
					variables: {
						id: parseInt(this.$route.params.id)
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.showMessage = true;
					this.message = "Completado";
					this.localProductos = [];
					for (
						let index = 0;
						index < data.data.localId[0].precio.length;
						index++
					) {
						this.localProductos.push(
							this.firstLetterUpperCase(
								data.data.localId[0].precio[index].producto.producto
							)
						);
					}
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.showMessage = true;
					this.message = "Error en carga, por favor vuelva a intentarlo";
					this.$store.state.isLoading = false;
				});
		},
		resetData() {
			this.data = [
				{
					id: parseInt(this.$route.params.id),
					fechaInicio: null,
					fechaFin: null,
					dataInicio: 0,
					dataFin: 10,
					filtro: this.filtroBusqueda,
					producto: null
				}
			];
			this.fechaInicio = null;
			this.fechaFin = null;
		},
		nuevaBusqueda() {
			//Validacion de data
			let fechaInicio = new Date(this.fechaInicio);
			let fechaFin = new Date(this.fechaFin);
			let ejecutarQuery = false;

			if (fechaInicio > fechaFin) {
				alert("La fecha de inicio tiene que ser antes de la fecha de fin");
			} else {
				if (this.fechaInicio === null && this.fechaFin === null) {
					this.filtroFecha = false;
					this.data = [
						{
							id: parseInt(this.$route.params.id),
							fechaInicio: null,
							fechaFin: null,
							dataInicio: 0,
							dataFin: 10,
							filtro: this.filtroBusqueda,
							producto: productoData(this.productoOrdenCompra)
						}
					];
					ejecutarQuery = true;
				} else {
					if (this.fechaInicio === null || this.fechaFin === null) {
						alert("Por favor elegir fecha de inicio y fin.");
					} else {
						this.filtroFecha = true;
						this.data = [
							{
								id: parseInt(this.$route.params.id),
								fechaInicio: this.fechaInicio,
								fechaFin: this.fechaFin,
								dataInicio: 0,
								dataFin: 10,
								filtro: this.filtroBusqueda,
								producto: productoData(this.productoOrdenCompra)
							}
						];
						ejecutarQuery = true;
					}
				}
			}
			if (ejecutarQuery && this.filtroBusqueda == "producto") {
				this.listaProductoOrdenCompraAcumulado();
			}
		},
		mostrarLista(index) {
			this.$set(this.mostrarDetalle, index, !this.mostrarDetalle[index]);
		},
		async cuentasCobrarQuery() {
			this.showMessage = true;
			this.message = "Cargando, por favor espere";
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/cuentasPagarProveedor.gql"),
					variables: {
						data: this.data
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.cuentasPagarProveedor = data.data.cuentasPagarProveedor[0];
					this.showMessage = true;
					this.message = "Completado";
					//Variable de control para mostrar o no detalles
					for (let i = 0; i < 10; i++) {
						this.mostrarDetalle.push(false);
						this.selectRefresh[i] = !this.selectRefresh[i];
					}
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.showMessage = true;
					this.message = "Error en carga, por favor vuelva a intentarlo";
					this.$store.state.isLoading = false;
				});
		},
		async guardarCambios() {
			this.$store.state.isLoading = true;
			if (this.$store.state.role === "superAdmin") {
				await this.guardarCambioProveedores();
			}
			await this.guardarCambiosCuentasPagar();
			this.$store.state.isLoading = false;
		},
		async guardarCambioProveedores() {
			this.$store.state.isLoading = true;
			this.$apollo
				.mutate({
					// Query
					mutation: require("@/graphql/mutations/CambiarProveedores.gql"),
					// Parameters
					variables: {
						input: this.finalArrayCambioProveedores
					},
					fetchPolicy: "no-cache"
				})
				.then(() => {
					this.$store.state.isLoading = false;
				})
				.catch(() => {
					this.$store.state.isLoading = false;
					alert("Se ha producido un error por favor vuelva a intentarlo");
				});
		},
		guardarCambiosCuentasPagar() {
			let estadoActual = "";
			let actualizarPagos = [];

			for (
				let i = 0;
				i < this.cuentasPagarProveedor.listaOrdenCompra.length;
				i++
			) {
				if (
					this.cuentasPagarProveedor.listaOrdenCompra[i].estado ===
						"No Pagado" ||
					this.cuentasPagarProveedor.listaOrdenCompra[i].estado === "0" ||
					this.cuentasPagarProveedor.listaOrdenCompra[i].estado === 0
				) {
					estadoActual = "0"; // No Pagado
				} else {
					estadoActual = "1"; // Pagado
				}
				actualizarPagos.push({
					local_id: parseInt(this.$route.params.id),
					compras_id: parseInt(
						this.cuentasPagarProveedor.listaOrdenCompra[i].id
					),
					estado: estadoActual
				});
			}
			this.showMessage = true;
			this.message = "Guardando Cambios, por favor espere";
			this.$store.state.isLoading = true;
			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation($data: [PagoProveedores]) {
							actualizarPagoProveedores(data: $data) {
								id
							}
						}
					`,
					// Parameters
					variables: {
						data: actualizarPagos
					},
					fetchPolicy: "no-cache"
				})
				.then(() => {
					this.cuentasCobrarQuery();
					//Variable de control para mostrar o no detalles
					for (let i = 0; i < 10; i++) {
						this.mostrarDetalle.push(false);
						this.selectRefresh[i] = !this.selectRefresh[i];
					}
					this.showMessage = true;
					this.message = "Cambios guardados con exito";
					this.$store.state.isLoading = false;
					alert("Cambios guardados con exito");
				})
				.catch(() => {
					this.$store.state.isLoading = false;
					this.showMessage = true;
					this.message =
						"Se ha producido un error por favor vuelva a intentarlo";
					alert("Se ha producido un error por favor vuelva a intentarlo");
				});
		},
		cambiarEstado(compra, estado) {
			compra.estado = estado;
		},
		verificarEstado(estado) {
			if (estado === 1) {
				return "Pagado";
			} else return "No Pagado";
		},
		cargarDataAnterior() {
			this.data[0].dataInicio -= 10;
			this.data[0].dataFin -= 10;
			this.cuentasCobrarQuery();
		},
		cargarDataSiguiente() {
			this.data[0].dataInicio += 10;
			this.data[0].dataFin += 10;
			this.cuentasCobrarQuery();
		}
	}
};
</script>
