<template>
  <div class="mb-10">
    <table id="Proveedores" class="table-auto mt-4">
      <thead>
        <tr>
          <th class="border-2 px-4 py-2">#Orden de compra</th>
          <th class="border-2 px-4 py-2">Fecha de compra</th>
          <th class="border-2 px-4 py-2">Producto</th>
          <th class="border-2 px-4 py-2">Cantidad</th>
          <th class="border-2 px-4 py-2">Unidad</th>
          <th class="border-2 px-4 py-2">Cuentas por pagar Bs</th>
          <th class="border-2 px-4 py-2">Cuentas por pagar $</th>
          <th class="border-2 px-4 py-2">Estado</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(compra, k) in listaProductosOrdenCompra" :key="k">
          <td class="border-2 px-4 py-4 text-center">
            <router-link
              class="hover:text-blue-500 hover:underline"
              :to="{
                name: 'PedidoID',
                params: {
                  id: compra.id.padStart(4, '0'),
                },
              }"
              >{{ compra.id.padStart(4, "0") }}</router-link
            >
          </td>
          <td class="border-2 px-4 py-4 text-center">
            {{ formatoFecha(compra.fecha_entrega) }}
          </td>
          <td class="border-2 px-4 py-4 text-center">
            {{ firstLetterUpperCase(compra.producto) }}
          </td>
          <td class="border-2 px-4 py-1 text-center">
            {{ formato(compra.cantidad_adquirida) }}
          </td>
          <td class="border-2 px-4 py-1 text-center">
            {{ firstLetterUpperCase(compra.unidad) }}
          </td>
          <td class="border-2 border-l-2 px-4 py-4 text-center">
            {{
              formato(
                parseFloat(compra.total_usd) * parseFloat(compra.tasa_dia)
              )
            }}
          </td>
          <td class="border-2 border-l-2 px-4 py-4 text-center">
            {{ formato(compra.total_usd) }}
          </td>
          <td class="border-2 border-l-2 px-4 py-4 text-center">
            <div v-if="compra.estado === 0 || compra.estado === '0'">
              <p class="text-red-600">No Pagado</p>
            </div>
            <div v-else>
              <p class="text-green-600">Pagado</p>
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td class="border-2 px-4 py-4 text-center font-bold">Total</td>
          <td class="border-2 px-4 py-4 text-center font-bold">
            {{ formato(cantidadTotal()) }}
          </td>
          <td class="border-2 px-4 py-4 text-center font-bold">
            {{ firstLetterUpperCase(listaProductosOrdenCompra[0].unidad) }}
          </td>
          <td class="border-2 px-4 py-4 text-center font-bold">
            {{ formato(totalBs) }}
          </td>
          <td class="border-2 px-4 py-4 text-center font-bold">
            {{ formato(totalUsd) }}
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formato, firstLetterUpperCase, formatoFecha } from "@/functions.js";

export default {
  props: {
    listaProductosOrdenCompra: Array,
    totalBs: Number,
    totalUsd: Number
  },
  methods: {
    formato,
    firstLetterUpperCase,
    formatoFecha,
    cantidadTotal() {
      let totalCantidadProducto = 0;

      for (let i = 0; i < this.listaProductosOrdenCompra.length; i++) {
        totalCantidadProducto += this.listaProductosOrdenCompra[i]
          .cantidad_adquirida;
      }
      return totalCantidadProducto;
    }
  }
};
</script>

<style></style>
