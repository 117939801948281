<template>
  <div>
    <table id="Proveedores" class="w-full">
      <thead>
        <tr>
          <th class="border-2 px-4 py-2">Producto</th>
          <th class="border-2 px-4 py-2">Unidad</th>
          <th class="border-2 px-4 py-2">Cantidad</th>
          <th class="border-2 px-4 py-2">Precio Unitario Bs</th>
          <th class="border-2 px-4 py-2">Precio Unitario USD</th>
          <th class="border-2 px-4 py-2">Total Bs</th>
          <th class="border-2 px-4 py-2">Total USD</th>
          <th
            v-if="$store.state.isLoggedIn && $store.state.role === 'superAdmin'"
            class="border-2 px-4 py-2"
          >
            Cambiar Proveedor
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(producto, k) in listaProductos" :key="k">
          <td class="border-2 px-4 py-4 text-center">
            {{ firstLetterUpperCase(producto.producto) }}
          </td>
          <td class="border-2 px-4 py-4 text-center">
            {{ firstLetterUpperCase(producto.unidad) }}
          </td>
          <td class="border-2 px-4 py-4 text-center">
            {{ formato(producto.cantidad) }}
          </td>
          <td class="border-2 px-4 py-4 text-center">
            {{
              formato(
                parseFloat(producto.total_bs) / parseFloat(producto.cantidad)
              )
            }}
          </td>
          <td class="border-2 px-4 py-4 text-center">
            {{
              formato(
                parseFloat(producto.total_usd) / parseFloat(producto.cantidad)
              )
            }}
          </td>
          <td class="border-2 px-4 py-1 text-center">
            {{ formato(producto.total_bs) }}
          </td>
          <td class="border-2 px-4 py-4 text-center">
            {{ formato(producto.total_usd) }}
          </td>
          <td
            v-if="$store.state.isLoggedIn && $store.state.role === 'superAdmin'"
            class="border-2 border-l-2 px-4 py-4 text-center"
          >
            <input
              class="rounded-md border w-20 text-gray-900"
              :class="
                arrayStyle[k]
                  ? ' bg-green-300 hover:bg-green-400'
                  : 'bg-blue-200 hover:bg-blue-300'
              "
              type="button"
              value="Cambiar"
              @click="
                $emit('cambiarProveedor', {
                  pedidoId: producto.id,
                  showWindow: true,
                  proveedores: producto.proveedores,
                  styleIndex: { parentIndex: baseIndex, childIndex: k },
                })
              "
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formato, firstLetterUpperCase } from "@/functions.js";
export default {
  props: {
    listaProductos: Array,
    arrayStyle: Array,
    baseIndex: Number
  },
  methods: {
    formato,
    firstLetterUpperCase
  },
  created() {
    if (this.arrayStyle.length < 1) {
      this.listaProductos.forEach(() => {
        this.arrayStyle.push(false);
      });
    }
  },
};
</script>

<style></style>
